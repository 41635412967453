//
// ionic cap sync
// ionic capacitor build ios
// After xcode opens goto ios/App/Pods/Target Support Files/Pods-App/Pods-App-frameworks.sh
// Add the -f on line 44 :  source="$(readlink  -f "${source}")"
// Build for running
// Archive
//
export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'SEROUND',

  // App Description (full name)
  appDescription: 'Security Electronic Rounding',

  // App Version Number
  appVersion: '6.0.1',

  // App's REST Service API Root URL
  apiUrl: 'https://mobiledev.api.baystatehealth.org/serounds',

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.caseshare.apps.baystatehealth.org',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://mobile.baystatehealth.org/appstore/',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set theme preference: user, light, dark
  // 'user' option will allow user to select theme preference (recommended).
  // 'light' and 'dark' will force their respective themes and not allow user to change.
  theme: 'user',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: false,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require PIN code enabled on device
  // Patient/Secure Apps will require that the PIN code is enabled
  // If PIN is not in use, app will block user
  requirePin: true,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: true,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 20 minutes = 1200000
  timeoutThreshold: 1200000,
};
